@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
  }

  html,
  body {
    @apply m-0;
    @apply p-0;
    @apply bg-page-base;
    @apply text-white;
  }

  h1 {
    @apply text-white;
  }

  a {
    @apply color-inherit;
    @apply no-underline;
  }
}

@layer components {
  .text-lg1 {
    @apply font-bold text-large;
  }

  .text-t1 {
    @apply font-extrabold text-title1;
  }

  .text-t2 {
    @apply font-extrabold text-title2;
  }

  .text-t3 {
    @apply font-extrabold text-title3;
  }

  .text-h1 {
    @apply font-bold text-headline1;
  }

  .text-h2 {
    @apply font-semibold text-headline2;
  }

  .text-h3 {
    @apply font-normal text-headline3;
  }

  .text-h4 {
    @apply font-semibold text-headline4;
  }

  .text-s1 {
    @apply font-normal text-subhead1;
  }

  .text-s2 {
    @apply font-bold uppercase text-subhead2;
  }

  .text-st2 {
    @apply font-medium text-subtitle2;
  }

  .text-b2 {
    @apply font-extrabold text-button2;
  }

  .text-c1 {
    @apply font-bold uppercase text-caption1;
  }

  .text-c2 {
    @apply font-bold uppercase text-caption2;
  }

  .text-i {
    @apply font-bold uppercase text-indicator;
  }

  .grid-cols-variable {
    grid-template-columns: repeat(var(--grid-cols, 2), minmax(0, 1fr));
  }

  .grid-rows-variable {
    grid-template-rows: repeat(var(--grid-rows, 2), minmax(0, 1fr));
  }

  .aspect-square {
    aspect-ratio: 1 / 1;
  }

  .shadow-right-edge {
    box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
  }

  .dark .shadow-right-edge,
  [data-mode='dark'],
  .shadow-right-edge {
    box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.1);
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .iterate-half {
    -webkit-animation-iteration-count: 0.5;
    animation-iteration-count: 0.5;
  }

  .iterate-once {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

@supports (font-variation-settings: normal) {
  html {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;
  }
}

#nprogress .bar {
  background: #00ff47 !important;
}
